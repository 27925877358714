module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-ea045a71b3/0/cache/gatsby-remark-images-npm-6.6.0-45ea520599-367520e4ca.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":912,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-image-virtual-3835ea3878/0/cache/gatsby-plugin-image-npm-2.6.0-0fea0a9d1a-f55fc9380b.zip/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-2cb2dbb7b5/0/cache/gatsby-plugin-manifest-npm-4.6.0-56378513ae-91cd06db9c.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Droid Morning by Bonny","start_url":"/","background_color":"#ffffff","icon":"./src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"efaac346c6dada89c144daba75b2a3fd"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-mdx-virtual-e2dc37f309/0/cache/gatsby-plugin-mdx-npm-3.6.0-0058091746-f6b4f658c6.zip/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo","commonmark":false},
    },{
      plugin: require('../.yarn/cache/gatsby-plugin-web-font-loader-npm-1.0.4-e6329b179e-7cb4df8ed5.zip/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Pretendard"],"urls":["https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css"]}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-e682d99323/0/cache/gatsby-plugin-google-gtag-npm-4.6.0-2ab70ae6bb-82ef23ffa1.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-F3B7NXNV11"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
