import React from "react";

import ImgLogo from "../assets/img_logo.png";
import * as styles from "./Logo.css";

const Logo: React.FC = () => {
  return (
    <div className={styles.container}>
      <img className={styles.logoImg} src={ImgLogo} alt="Logo" />
    </div>
  );
};

export default Logo;
